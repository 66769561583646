import React from "react";

import {  useRouter } from "next/navigation";
import Link from 'next/link'

import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import {
  Cog6ToothIcon,
  InboxArrowDownIcon,
  LifebuoyIcon,
  UserCircleIcon,
} from "@heroicons/react/16/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { PmStore } from "@/state/store";
import {HiOutlineLogout} from "react-icons/hi";

function ProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  let navigate = useRouter();
  const closeMenu = () => setIsMenuOpen(false);
  let store = PmStore();

  function LogOut() {
    store.resetToken();
    navigate.push("/");
    closeMenu();
  }

  const profileMenuItems = [
    {
      label: "My Profile",
      icon: UserCircleIcon,
    },
    {
      label: "Edit Profile",
      icon: Cog6ToothIcon,
    },
    {
      label: "Inbox",
      icon: InboxArrowDownIcon,
    },
    {
      label: "Help",
      icon: LifebuoyIcon,
    },
  ];

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <UserCircleIcon strokeWidth={2.5} height={25} />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={closeMenu}
              className="flex items-center gap-2 rounded"
            >
              {React.createElement(icon, {
                className: "h-4 w-4",
                strokeWidth: 2,
              })}
              <Typography as="span" variant="small" className="font-normal">
                {label}
              </Typography>
            </MenuItem>
          );
        })}
        <MenuItem
          onClick={() => LogOut()}
          className="flex items-center gap-2 rounded"
        >
          <HiOutlineLogout className="h-4 w-4" />
          <Typography as="span" variant="small" className="font-normal">
            Logout
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default ProfileMenu;
