"use client";

import { Spinner } from "@material-tailwind/react";

import {useEffect, useRef, useState} from "react";
import {AuthenticationLoggedInEvent, eventBus, PmStore} from "@/state";
import {gql, useLazyQuery} from "@apollo/client";

export function OrganizationToggle() {
  const [Loading, setLoading] = useState(true);
  const state = PmStore();
  const [SelectedOrg, SetSelectedOrg] = useState({});



  const knownOrgsRef = useRef(PmStore.getState().knownOrganizations);
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => PmStore.subscribe(
        (state) => (knownOrgsRef.current = state.knownOrganizations)
  ), [])


  const isAuthenticatedRef = useRef(PmStore.getState().isAuthenticated);
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => PmStore.subscribe(
      (state) => (isAuthenticatedRef.current = state.isAuthenticated)
  ), [])




  const selectedOrgRef = useRef(PmStore.getState().selectedOrganization);
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => PmStore.subscribe(
      (state) => (selectedOrgRef.current = state.selectedOrganization)
  ), [])




  const gqlMyOrgs = gql`
    query getOrgs {
      myOrganizations {
        id
        name
      }
    }
  `;

  const [apiGetAssetType] = useLazyQuery(gqlMyOrgs);



  function GetOrganizations() {
    apiGetAssetType().then((result) => {
      let data = result?.data?.myOrganizations;
      state.setKnownOrganizations(data);
    });
  }

  const MINUTE_MS = 1000;

  useEffect(() => {
    const interval = setInterval(() => {


      if (isAuthenticatedRef.current)
      {
        //
        // console.log("Known Org Count:", knownOrgsRef?.current?.length)
        // console.log("State Selected Org Id:", selectedOrgRef.current)
        // console.log("Page Selected Org Id:", SelectedOrg.id)


        //check if state has known orgs
        if(knownOrgsRef?.current?.length === 0)
        {
          GetOrganizations();
          console.log('state known org length: ',knownOrgsRef.current.length);
        }
        else if(!selectedOrgRef.current)
        {
          state.setSelectedOrg(knownOrgsRef.current[0]);
        }
        else
        {
          setLoading(false);
        }


        // //Check if state doesn't have a selected org
        // if(!selectedOrgRef.current.id)
        // {
        //   let selected = knownOrgsRef?.current[0];
        //   state.setSelectedOrg(selected);
        //   SetSelectedOrg(selected);
        // }


        //
        // //check if state knows about a selected org, bug page state doesn't, update it
        // if( selectedOrgRef.current.id && SelectedOrg.id === null)
        // {
        //     console.log("Page State Selected Org not set, state is set")
        //   SetSelectedOrg(selectedOrgRef.current);
        // }
        //
        //
        // //Check if the state selected org has changed from current page state
        // if( selectedOrgRef.current.id && SelectedOrg.id && SelectedOrg?.id !== selectedOrgRef?.current?.id)
        // {
        //   SetSelectedOrg((prev) => (selectedOrgRef.current));
        // }
        //
        //
        // if(knownOrgsRef.current.length > 0 && SelectedOrg.id)
        // {
        //   console.log("setting loading to false");
        //   setLoading(false);
        // }
        // else {
        //   console.log("not setting loading to false");
        // }




      }






    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);




  function SetupSelectedOrg() {
    if (knownOrgsRef?.current.length === 0) return;
    if (state?.selectedOrganization === null) {
      let selected = knownOrgsRef?.current[0];
      state.setSelectedOrg(selected);
      SetSelectedOrg(selected);
    }
  }

  function SetupLoggedInUser() {
    if (state?.isAuthenticated) {
      if (state?.knownOrganizations.length === 0) {
        GetOrganizations();
      } else {
        SetupSelectedOrg();
      }
    }
  }

  eventBus.subscribe((event) => {
    switch (event.type) {
      case AuthenticationLoggedInEvent:
        SetupLoggedInUser();
        break;
      default:
        break;
    }
  });

  if (Loading) {
    return (
      <div className={" max-w-100 pt-2"}>
        <Spinner />

      </div>
    );
  }

  return (
    <div className={" max-w-100  -mt-1"}>
      <select
        id="location"
        name="location"
        value={selectedOrgRef.current?.id ?? knownOrgsRef.current[0]?.id}
        onChange={(e) => state.setSelectedOrg(e.target.value)}
        className=" mt-2 block w-full max-w-100 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        {knownOrgsRef?.current?.map((row, index) => {
          return (
            <option key={index} value={row}>
              {row.name}
            </option>
          );
        })}
      </select>

    </div>
  );
}
