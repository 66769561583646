import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const PmStore = create(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      selectedOrganization: null,
      token: null,
      knownOrganizations: [],

      // setToken: (tok) => set((state) => ({ token: tok, isAuthenticated: true })),
      setToken: (newToken) =>
        set((state) => ({
          token: newToken,
          isAuthenticated: true,
        })),

      resetToken: () =>
        set((state) => ({
          token: null,
          isAuthenticated: false,
          knownOrganizations: [],
          selectedOrganizationId: null,
        })),

      setSelectedOrg: (org) => {
        set((state) => ({
          selectedOrganization: org,
        }));
      },

      setKnownOrganizations: (orgs) =>
        set((state) => ({
          knownOrganizations: orgs,
        })),
    }),
    {
      name: "pm-store", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
