"use client"

import {
    Bars4Icon,
    GlobeAmericasIcon, NewspaperIcon,
    PhoneIcon, RectangleGroupIcon,
    SquaresPlusIcon,
    SunIcon, TagIcon,
    UserGroupIcon
} from "@heroicons/react/24/solid";
import React, {useState} from "react";

import {  useRouter } from "next/navigation";

import Link from 'next/link'

import {Collapse, ListItem, Menu, MenuHandler, MenuItem, MenuList, Typography} from "@material-tailwind/react";
import {ChevronDownIcon} from "@heroicons/react/24/outline";

const navListMenuItems = [
    {
        title: "Platform",
        description: "Explore the features of the Rental Flow Platform",
        icon: SquaresPlusIcon,
        destination: "/contact",
    },

    {
        title: "About Us",
        description: "Meet and learn about our dedication",
        icon: UserGroupIcon,
        destination: "/about",
    },



    {
        title: "Contact",
        description: "Find the perfect solution for your needs.",
        icon: PhoneIcon,
        destination: "/contact",
    },


];

export function ExploreMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const renderItems = navListMenuItems.map(
        ({ icon, title, description, destination }, key) => (
            <Link href={destination} key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg">
                    <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
                        {" "}
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 text-gray-900 w-6",
                        })}
                    </div>
                    <div >
                        <Typography
                            variant="h6"
                            color="black"
                            className="flex items-center text-sm font-bold"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !font-medium text-blue-gray-500"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </Link>
        )
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{ mainAxis: 20 }}
                placement="bottom"
                allowHover={false}
            >
                <MenuHandler>
                    <Typography as="div" variant="small" className="font-medium">
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            Explore
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                    <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}