// const eventBus = {
//     on(event, callback) {
//         document.addEventListener(event, (e) => callback(e.detail));
//     },
//     dispatch(event, data) {
//         document.dispatchEvent(new CustomEvent(event, { detail: data }));
//     },
//     remove(event, callback) {
//         document.removeEventListener(event, callback);
//     },
// };

// export default eventBus;

import { Subject } from "rxjs";

const eventBus = new Subject();

export default eventBus;



export var Asset_NewAssetTypeDrawer_Open = "asset.new_asset_type_drawer.open";
export var Asset_NewAssetDrawer_Open = "asset.new_asset_drawer.open";


export var AuthenticationLoggedInEvent = "auth.logged_in";





export var Application_Add_Note_Drawer_Open = "application.add_note_drawer.open";
export var Application_Notification_Updated = "application.notification.updated";


export var Application_Accept_Modal_Open = "application.accept_modal.open";
export var Application_Updated = "application.updated";
export var Application_Activity_Updated = "application.activity_updated";



export var Site_Maps_Updated = "site-maps.updated";

export var AssetContracts_Updated = "asset_contracts.updated";
